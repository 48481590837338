import { useMutation } from '@apollo/client';
import { VIEW_BLUR_SIGNUP } from '../../graphql/user-mutations';
import { getTestWithProbSSR } from '../../helpers/ssr';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { USER_TEST_GROUP } from '../../consts/profile.const';
import CookieService from '../../services/cookie.service';
import useGetProbabilityTest from '../../services/hooks/useGetProbabilityTest';

export default function useMutateViewBlurSignup() {
    const [mutateViewBlurSignup] = useMutation(VIEW_BLUR_SIGNUP);
    const { getProbTest } = useGetProbabilityTest();
    const checkBlurSignupTest = async () => {
        const isBlurTest = await getTestWithProbSSR({
            getProbTest,
            cookie: COOKIE_PARAM_NAME.FRONT_BLUR_SIGNUP,
            userTestGroup: USER_TEST_GROUP.FRONT_BLUR_SIGNUP,
        });

        if (isBlurTest !== null) {
            await mutateViewBlurSignup({ variables: { testGroup: isBlurTest, sessionUuid: CookieService.get(COOKIE_PARAM_NAME.SESSION_UUID) } });
        }

        return isBlurTest;
    };

    return { mutateViewBlurSignup, checkBlurSignupTest };
}
