import React from 'react';
import bemCn from 'bem-cn';
import loadable from '@loadable/component';

import ModalMobile from '../../components/modal-mobile';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';

const RenderSignUpEmail = loadable(() => import('../../pages/host-landing/render-sign-up-email'));
const bSignUp = bemCn('host-landing');

const BlurSignupModal = ({ isBlurTest, setIsBlurTest, isMobile, useStateSSR }) => {
    const [isFocused, setIsFocused] = useStateSSR(false);

    return (
        <ModalMobile
            isBlur
            isCentered
            isOpen={isBlurTest}
            toggle={() => setIsFocused(false)}
            contentClassName={isMobile ? 'border-radius-10 mx-2' : ''}
        >
            <p
                style={{ top: isFocused ? '-5%' : '-8%', right: 0, cursor: 'pointer' }}
                className="position-absolute text-end snif-p snif-regular mb-0 text-white"
                onClick={() => setIsBlurTest(false)}
            >
                <span>Close</span> <SvgCross2 />
            </p>
            <p className="snif-m1 snif-semibold text-center mb-3">Sign up</p>
            <p className="snif-s1 text-medium text-center mb-3">You need to sign up or sign in before continuing</p>
            <RenderSignUpEmail
                {...{
                    b: bSignUp,
                    signUp: true,
                    isFocused,
                    setIsFocused,
                    from: typeof window === 'undefined' ? '' : window.location.href,
                    showOptions: true,
                    useStateSSR,
                    afterSignUp: (re_uri = '') => {
                        window.location.replace(re_uri);
                        window.location.reload();
                    },
                }}
            />
        </ModalMobile>
    );
};

export default BlurSignupModal;
